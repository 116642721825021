<template>
  <pre>
    {{ dump }}
  </pre>
</template>
<script>
export default {
  props: ['value'],
  computed: {
    dump() {
      return JSON.stringify(this.value, null, 2)
    }
  }
}
</script>
